import gql from 'graphql-tag'

export const USERS_Q = gql`
    {
        users{
            id
            first_name
            last_name
            role
            email
            active
            teacher
            photo
            user_type
            teacher_subjects{
                id
                class_id
                subjects
                abilities
            }
        }
    }
`
export const TEACHERS_Q = gql`
    {
        teachers{
            id
            first_name
            last_name
        }
    }
`

export const TERMS_CLASSES_TEACHERS_Q = gql`
    {
        schoolTerms {
            id
            name
        }
        terms{
            id
            name
            tag_id
            ay_term_id
            classes
        }
        tags{
            id
            name
        }
        defaultAy {
            academic_year_id
            term_id
            users
            enabled
        }
        classes{
            id
            name
            cover
            class_teacher_id
            teacher_name
        }
        academicYearClasses{
            id
            name
            cover
            class_teacher_id
            teacher_name
        }
        teachers{
            id
            first_name
            last_name
        }
        academicYears{
            id
            current
            completed
            name
            start_date
            end_date
            formatted_start_date
            formatted_end_date
            most_recent
            terms{
                id
                term_id
                classes{
                    id
                    name
                }
                formatted_vacation_date
                formatted_next_term_reopening_date
                vacation_date
                next_term_reopening_date
                term_name
                term_start_date
                term_end_date
                midterms {
                    title
                    start_date
                    end_date
                }
                holidays {
                    title
                    date
                }
                formatted_term_start_date
                formatted_term_end_date
                tag_id
                is_promotional_term
            }
        }
    }
`
export const ACADEMIC_YEARS_Q = gql`
    {
        academicYears{
            id
            current
            completed
            name
            start_date
            end_date
            formatted_start_date
            formatted_end_date
            most_recent
            terms{
                id
                term_id
                classes{
                    id
                    name
                }
                formatted_vacation_date
                formatted_next_term_reopening_date
                vacation_date
                next_term_reopening_date
                term_name
                term_start_date
                term_end_date
                midterms {
                    title
                    start_date
                    end_date
                }
                holidays {
                    title
                    date
                }
                formatted_term_start_date
                formatted_term_end_date
                tag_id
                is_promotional_term
            }
        },
        terms{
            id
            name
            ay_term_id
            tag_id
            classes
        }
    }
`
export const GRADES_GRADING_SYSTEM_SUBJECTS_AGGREGATES_Q = gql`
    {
        grades{
            id
            label
            value
            remark
        }
        gradingSystems{
            id
            name
            classes{
                id
                name
                teacher_name
            }
            grades{
                id
                grade_id
                label
                min_score
                max_score
                remark
            }
        }
        subjects{
            id
            name
            tag
        }
        aggregates{
            id
            title
            best_core_count
            core_subjects
            best_elective_count
            elective_subjects
            classes
            exclude_subjects
        }
    }
`
export const PROMOTION_STANDARDS_Q = gql`
    {
        promotionStandards{
            id
            title
            absence_rate
            min_cum_average
            max_cum_average
            consider_improvement
            improvement_rate
            classes
        }
        promotionFlow{
            id
            next_class
            prev_class
            current_class
        }
    }
`
export const STAFF_PAYMENTS_Q = gql` query($date: String!)
    {
        staffPayments(date: $date) {
            id
            type
            status
            user_id
            staff_id
            photo
            first_name
            last_name
            base_salary
            net
            paye
            t1
            t2
            t3
            payment_id
        }
    }
`
export const STAFF_DEPARTMENTS_POSITIONS_Q = gql`
    {
        positions{
            id
            title
        }
        departments{
            id
            title
        }
        banks {
            name
            code
        }
        staffMembers{
            id
            staff_id
            user_id
            gender
            dob
            emergency_contact_name
            emergency_contact_phone
            address
            position_id
            department_id
            employment_type
            employment_start_date
            employment_end_date
            employment_end_reason
            ssnit
            bank_name
            bank_code
            bank_account_name
            bank_account_number
            base_salary
            pays_ssnit
            pays_tax
            first_name
            last_name
            photo
            phone
            email
            status
            department
            position
            age
        }
    }
`
export const CUSTOMIZATIONS_Q = gql`
    {
        customizations{
            id
            type
            document_version
            customization{
                show_logo
                show_student_id
                show_emergency_phone
                show_class
                show_dob
                is_gradient
                first_color
                second_color
                bg_color
                alt_bg_color
                text_color
                issued_date_text_color
                barcode_color
            }
        }
    }
`
export const REMARKS_Q = gql`
    {
        remarks{
            id
            title
            remarks{
                comment
                min_average
                max_average
                advanced
                alternative_remarks{
                    improving
                    dis_improving
                }
            }
            classes
        }
    }
`
export const ROLES_USERS_PERMISSIONS_Q = gql`
    {
        roles{
            id
            name
            permissions{
                id
                name
                resource
            }
        }
        permissions{
            id
            name
            resource
        }
        users{
            id
            first_name
            last_name
            active
            email
            phone
            photo
            role
            teacher
            user_type
            teacher_subjects{
                id
                class_id
                subjects
                abilities
            }
        }
    }
`
export const GET_CALENDAR_EVENTS_Q = gql`
    query ($input: CalendarEventInput!){
        getCalendarEvents(input: $input){
            data{
                id
                title
                start
                end
                all_day
                included_note
                excluded_note
                included_classes
                excluded_classes
                notify
                type
                extra_dates{
                    date
                }
                notification_channels
                extendedProps{
                    calendar
                }
                processing
                failed
            }
            paginatorInfo{
                count
                currentPage
                perPage
                hasMorePages
                firstItem
                lastItem
            }
        }
        alerts{
            id
            title
        }
    }
`
export const RETRIEVE_GUARDIAN_BY_PHONE_Q = gql`
    query ($phone: String!){
        guardian(phone: $phone){
            id
            first_name
            last_name
            phone
        }
    }
`
export const FETCH_STUDENTS_Q = gql`
    query ($class_id: Int!,$from_web: Boolean){
        students(class_id: $class_id,from_web: $from_web){
            id
            student_id
            first_name
            last_name
            middle_name
            address
            interests
            gender
            dob
            age
            class_id
            photo
            created_at
            status
            parents{
                id
                parent_id
                first_name
                last_name
                phone
                relation
            }
        }
    }
`
export const FETCH_ASSESSMENT_SETTINGS_Q = gql`
    {
        assessmentSettings{
            id
            class
            subject
            class_score_percentage
            exam_score_percentage
        }
    }
`
export const FETCH_FEE_SETTINGS_Q = gql`
    {
        feeSettings{
            id
            title
            status
            term_id
            term
            academic_year
            academic_year_id
            amount
            items{
                amount
                title
            }
            due_date
            display_date
            display_name
            classes
        }
    }
`
export const FETCH_BANKS_ADDRESSES_Q = gql`
    {
        school{
            name
            about
            alias
            new_alias
            email
            logo
            phone
            slogan
            id
            date_established
            apply_charges
            accept_online_payments
            active_sms
            trial_ends
            verified
            activated_sms_status
            creche_classes
            lng
            lat
        }
        #        banks{
        #            code
        #            name
        #        }
        #        bank{
        #            account_name
        #            account_number
        #            bank_code
        #            id
        #            recipient_code
        #        }
        countries{
            id
            name
        }
        regions{
            id
            country_id
            name
        }
        cities{
            id
            region_id
            name
        }
        address{
            id
            country_id
            region_id
            city_id
            town
            box_address
        }
    }
`
export const FETCH_ADDRESSES_Q = gql`
    {
        countries{
            id
            name
        }
        regions{
            id
            country_id
            name
        }
        cities{
            id
            region_id
            name
        }
    }
`
export const FETCH_STUDENTS_FOR_FEE_PAYMENTS_Q = gql`
    query ($class_id: Int!){
        studentsForFeePayment(class_id: $class_id){
            student_id
            receipt_id
            amount_paid
            fee_id
            fee_setting_id
            payment_mode
            is_full_payment
            pending_amount
            first_name
            last_name
            middle_name
            class_id
            photo
            total_amount_paid
            payer_name
            payer_phone
            fee_payment_date
            last_payment_class
            code
            title
        }
    }
`

export const FETCH_STUDENT_FOR_FEE_PAYMENT_Q = gql`
    query ($search: String!){
        studentToRecordFeesFor(search: $search){
            student_id
            receipt_id
            amount_paid
            is_full_payment
            fee_id
            pending_amount
            payment_mode
            first_name
            last_name
            class_id
            photo
            middle_name
            total_amount_paid
            payer_name
            payer_phone
            fee_payment_date
            title
        }
    }
`
export const LOAD_STUDENT_PENDING_FEE_Q = gql`
    query ($student_id: Int!,$fee_setting_id: Int!){
        studentPendingFee(fee_setting_id: $fee_setting_id,student_id: $student_id){
            due_date
            paid_amount
            pending_amount
            total_amount
        }
    }
`
export const GET_FEE_PROJECTIONS_Q = gql`
    query ($fee_setting_id: Int!){
        feeProjections(fee_setting_id: $fee_setting_id){
            id
            name
            collected_amount
            expected_amount
            pending_amount
        }
    }
`
export const GET_RECURRING_FEE_PROJECTIONS_Q = gql`
    query ($academic_year_id: Int!,$term_id: Int!,$fee_setting_id: Int!){
        recurringFeeProjections(academic_year_id:$academic_year_id,term_id:$term_id,fee_setting_id: $fee_setting_id){
            id
            name
            collected_amount
            expected_amount
            pending_amount
        }
    }
`
export const GET_VALID_CURRENT_AY_Q = gql`
    query {
        validCurrentAy{
            term_id
            academic_year_id
        }
    }
`
export const FETCH_FEE_PAYMENTS_Q = gql`
    query ($input: FeePaymentQueryInput!){
        feePayments(input: $input){
            data{
                id
                amount
                full_name
                photo
                student_id
                fee_setting_id
                payer_name
                payer_phone
                date
                actual_date
                payment_type
                receipt_id
                payment_mode
                payment_class
            }
            paginatorInfo{
                count
                currentPage
                perPage
                hasMorePages
                firstItem
                lastItem
            }
        }
    }
`
export const FETCH_STUDENT_ARREARS_Q = gql`
    query ($input: ArrearsInput!){
        arrears(input: $input){
            data{
                amount_paid
                arrears
                parent_name
                parent_phone
                relation
                due_date
                photo
                full_name
                receipt_id
                student_id
                fee_type
                total_amount
            }
        }
    }
`
export const FETCH_RECURRING_FEE_SETTINGS_Q = gql`
    {
        recurringFeeSettings{
            id
            title
            amount
            classes
            date
        }
    }
`
export const FETCH_RECURRING_FEE_PAYMENTS_Q = gql`
    query ($fee_setting_id: Int!, $class_id: Int!,$academic_year_id: Int!, $term_id: Int!){
        recurringFeePayments(fee_setting_id: $fee_setting_id,class_id: $class_id,academic_year_id:$academic_year_id,term_id: $term_id){
            expected_payment
            failed_payments{
                amount
                date
                fee_class_id
            }
            full_name
            photo
            student_id
            total_paid
        }

    }
`
export const FETCH_ATTENDANCE_LIST_Q = gql`
    query ($input: AttendanceListInput!){
        attendanceList(input: $input){
            id
            student_id
            first_name
            last_name
            middle_name
            photo
            absence_rate
            absent
            absent_with_permission
            attendance_status
            total_school_days
            present
        }

    }
`
export const FETCH_TERMINAL_ASSESSMENTS_Q = gql`
    query ($input: TerminalAssessmentInput!){
        terminalAssessments(input: $input){
            columns{
                key
                label
            }
            data{
                id
                photo
                first_name
                last_name
                middle_name
                results{
                    key
                    score
                }
            }
        }

    }
`
export const FETCH_STUDENTS_PERFORMANCE_Q = gql`
    query ($input: PerformanceInput!){
        studentsPerformance(input: $input){
            photo
            first_name
            middle_name
            last_name
            current_average
            previous_average
            improving
            status
        }

    }
`

export const FETCH_STUDENTS_RESULTS_Q = gql`
    query ($input: StudentResultsInput!){
        studentsResults(input: $input){
            students_results{
                student_id
                oid
                withheld
                photo
                first_name
                middle_name
                last_name
                attendance
                cum_average
                overall_position
                average
                aggregate
                total_score
                promoted_to
                results{
                    subject
                    class_score
                    exam_score
                    total_score
                    position
                    max_score
                    grade
                    remark
                }
            }
        }
        approvalStatus(input: $input)
    }`

export const CHECK_VERIFICATION_STATUS_Q = gql`{
    verificationStatus
}`

export const FETCH_USER_ACTIVITIES_Q = gql`
    query ($input: ActivitiesInput!){
        userActivities(input: $input){
            data{
                date
                created_at
                full_name
                photo
                summary
                type
            }
            paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                perPage
            }
        }
    }`

export const FETCH_SIGNATURES_Q = gql`
    query{
        signatures{
            id
            title
            document_type
            signed_by
            photo
            name
            signature
            updated_at
            classes
        }

    }
`
export const SEND_RESULTS_TO_PARENTS_Q = gql`query ($input: StudentResultsToParentsInput!) {
    sendStudentsResultsToParents(input:$input)
}
`
export const GENERATE_TERMINAL_REPORTS_Q = gql`query ($input: StudentReportInput!) {
    generateStudentsReport(input:$input){
        name
        path
    }
}`
export const FETCH_OTHER_INCOMES_Q = gql`query($term_id:Int,$academic_year_id:Int){
    otherIncomes(term_id: $term_id,academic_year_id: $academic_year_id) {
        id
        title
        amount
        date
        details
    }
}`
export const FETCH_EXPENSES_Q = gql`query($term_id:Int,$academic_year_id:Int){
    expenses(term_id: $term_id,academic_year_id: $academic_year_id) {
        id
        title
        amount
        date
        details
    }
}`
export const FETCH_TRANSFERS_Q = gql`query{
    transfers {
        id
        transferred_by
        amount
        date
        status
    }
}`

export const INITIATE_TRANSFER_Q = gql`query{
    initiateTransfer
}`
export const GET_SMS_BALANCE_Q = gql`query{
    smsBalance
}`
export const CONFIRM_TRANSFER_Q = gql`query($otp: String!){
    confirmTransfer(otp: $otp)
}`
export const GET_SCHOOL_Q = gql`query{
    school{
        name
        about
        alias
        new_alias
        email
        logo
        phone
        slogan
        id
        date_established
        apply_charges
        accept_online_payments
        active_sms
        verified
        trial_ends
        activated_sms_status
        creche_classes
        lng
        lat
    }
}`
export const FETCH_REQUIRED_DATA_Q = gql`query{
    terms{
        id
        name
        tag_id
        ay_term_id
        classes
    }
    users{
        id
        first_name
        last_name
        active
        email
        phone
        photo
        role
        teacher
        user_type
        teacher_subjects{
            id
            class_id
            subjects
            abilities
        }
    }
    academicYearClasses{
        id
        name
        class_teacher_id
        teacher_name
    }
    classes{
        id
        name
        class_teacher_id
        teacher_name
    }
    subjects{
        id
        name
        tag
    }
    academicYears{
        id
        current
        name
        start_date
        end_date
        formatted_start_date
        formatted_end_date
        terms{
            id
            term_id
            classes{
                id
                name
            }
            term_name
            term_start_date
            term_end_date
            formatted_term_start_date
            formatted_term_end_date
            tag_id
            is_promotional_term
        }
    }
    feeSettings{
        id
        title
        status
        term_id
        term
        academic_year
        academic_year_id
        amount
        items{
            amount
            title
        }
        due_date
        display_date
        display_name
        classes
    }
}`
export const READ_STUDENT_DASHBOARD_DATA_Q = gql`query{
    population {
        active
        females
        inactive
        males
    }
    ageGroup{
        males
        females
    }
    absentees{
        class
        name
        total_absents
        photo
    }
    attendanceToday{
        females_absent
        females_present
        males_absent
        males_present
    }
}`
export const GET_REVENUE_FOR_YEAR_Q = gql`query($ay_id:Int){
    revenueReport(ay_id: $ay_id){
        expenses
        earnings
    }
}`
export const READ_FINANCE_DASHBOARD_DATA_Q = gql`query($year: String,$ay_id:Int){
    paymentSummary(year: $year){
        balance
        other_income
        recurring
        total_expenses
        total_fees
        title
    }
    paymentsForCurrentTerm {
        balance
        other_income
        recurring
        total_expenses
        total_fees
        title
    }
    paymentsForCurrentYear {
        balance
        other_income
        recurring
        total_expenses
        total_fees
        title
    }
    paymentsForPrevYear {
        balance
        other_income
        recurring
        total_expenses
        total_fees
        title
    }
    paymentsForPrevTerm {
        balance
        other_income
        recurring
        total_expenses
        total_fees
        title
    }
    revenueReport(ay_id: $ay_id){
        expenses
        earnings
    }
    schoolArrears{
        recurring
        regular
    }
}`
export const CURRENT_ACADEMIC_YEAR_Q = gql`{
    currentAcademicYear{
        name
        term
        tag
        ay_term_id
        custom
        modules
    }
    hasMultipleAccounts
    hasMultipleTerms
}`
export const GET_DEFAULT_AY_Q = gql` query{
    defaultAy{
        academic_year_id
        term_id
        users
        enabled
    }
}`
export const FETCH_UNMARKED_ATTENDANCES_Q = gql`query($academic_year_id: Int!,$term_id: Int!){
    unmarkedAttendances(academic_year_id: $academic_year_id,term_id: $term_id){
        class_name
        class_teacher
        photo
        total
        dates
    }
}`
export const FETCH_BATCHES_Q = gql`query{
    batches{
        id
        name
    }
}`
export const FETCH_SUBSCRIPTION_AND_PRICING_Q = gql`{
    totalStudents
    pricings{
        id
        price
        title
        description
    }
    subscription{
        id
        on_trial
        subscribed
        plan_id
        trial_ends
        ends
        upgrade_duration
        total_students
        pricing{
            id
            freemium
            price
            title
            description
        }
    }
}`
export const GENERATE_SSNIT_PAYE_REPORT_Q = gql`query($date: String!,$staff_members: [Int!]!){
    generateSsnitPayeReport(date: $date,staff_members: $staff_members){
        name
        path
    }
}`
export const GENERATE_PAYSLIPS_Q = gql`query($date: String!,$staff_members: [Int!]!,$send_to_staff: Boolean!){
    generatePayslips(date: $date,staff_members: $staff_members,send_to_staff: $send_to_staff){
        name
        path
    }
}`
export const TRANSACTIONS_AND_BANKS_Q = gql`{
    walletBanks{
        id
        title
        account_number
        account_name
        bank_code
    }
    transactions{
        id
    }
}`
export const STAFF_ATTENDANCES_Q = gql` query($date: String!){
    staffAttendances(date: $date){
        id
        name
        photo
        is_present
        absence_reason
        reported_time
    }
}`
export const STAFF_LOCATIONS_Q = gql`{
    staffLocations{
        id
        title
        longitude
        latitude
        staff_members
    }
}`
export const DAILY_REPORTS_Q = gql`query($class_id:Int!, $date: String,$type: String!){
    dailyReports(class_id: $class_id, date: $date,type: $type){
        id
        photo
        student_name
        teacher
        message
    }
}`
export const REPORT_FORMS_Q = gql`{
    reportForms{
        id
        title
        type
        class_ids
        questions
    }
}`
export const GET_ACTIVE_TERMS_Q = gql`{
    activeTerms{
        id
        tag
        title
    }
}`
export const GET_ACTIVE_ACCOUNTS_Q = gql`{
    activeAccounts{
        id
        alias
        title
    }
}`
