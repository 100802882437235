<template>
  <div class="">
    <lenon-list-page
      :table-loading="tableLoading"
      :remote="true"
      title="Activities"
      :columns="columns"
      :rows="activities"
      :total-rows="totalRows"
      :current-page="currentPage"
      :show-refresh="false"
      :show-search="true"
      search-placeholder="Type search term + Enter"
      @onSearch="handleSearch"
      @onPageChange="handlePageChange"
      :show-profile-photo="true"
      @onPerPageChange="handlePerPageChange"
      @onSortChange="handleSortChange"
    >
      <template #type="{row}">
        <b-badge :variant="getStatusVariant(row.item.type)">
          {{ capitalizeFirstLetter(row.item.type) }}
        </b-badge>
      </template>
      <template #summary="{row}">
        <div v-html="row.item.summary" />
      </template>
    </lenon-list-page>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import { FETCH_USER_ACTIVITIES_Q } from '@/graphql/queries'

export default {
  name: 'Activities',
  components: {
    LenonListPage,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          label: 'Full Name',
          key: 'full_name',
        },
        {
          label: 'Action',
          key: 'type',
          // sortable: true,
        },
        {
          label: 'Summary',
          key: 'summary',
        },
        {
          label: 'Date',
          key: 'date',
          // sortable: true,
        },
      ],
      tableLoading: false,
      currentPage: 1,
      perPage: 5,
      searchTerm: null,
      initializing: false,
      sort: {
        column: 'created_at',
        order: 'desc',
      },
    }
  },
  computed: {
    activities() {
      return this.userActivities?.data
    },
    totalRows() {
      return this.userActivities?.paginatorInfo?.count
    },
    userActivities() {
      return this.$store.getters['auth/userActivities']
    },
  },
  mounted() {
    this.initializing = true
    this.getActivities(true)
  },
  methods: {
    getStatusVariant(type) {
      switch (type) {
        case 'created':
          return 'success'
        case 'deleted':
          return 'danger'
        default:
          return 'warning'
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getActivities(showLoader = true) {
      this.tableLoading = showLoader
      this.$apollo.query({
        query: FETCH_USER_ACTIVITIES_Q,
        variables: {
          input: {
            search: this.searchTerm,
            sort: this.sort,
            per_page: this.perPage,
            page: this.currentPage,
          },
        },
      })
        .then(res => {
          this.initializing = false
          this.tableLoading = false
          if (res.data && !res.errors) {
            this.$store.commit('auth/setUserActivities', res.data)
          }
        })
        .catch(err => {
          this.initializing = false
          this.tableLoading = false
          this.showError('Something went wrong, please try again')
        })
    },
    handleSearch(search) {
      this.searchTerm = search
      this.getActivities()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.getActivities()
    },
    handlePerPageChange(perPage) {
      if (!this.initializing) {
        this.perPage = perPage
        this.getActivities()
      }
    },
    handleSortChange(sort) {
      this.sort.column = sort.sortBy === 'type' ? 'event' : 'created_at'
      this.sort.order = sort.sortDesc ? 'desc' : 'asc'
      this.getActivities()
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
